import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { Helmet } from 'react-helmet'
import {Typography, Box} from '@mui/material'
import React, { useEffect, useState} from 'react';
import getFirebase from '../components/firebaseConfig.js'
getFirebase()
const auth = getAuth()
const Login = ()=>{
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [error, setError] = useState('')
	useEffect(()=>{
		onAuthStateChanged(auth, (user)=>{
			if(user){
				window.location="/adminView"
			}
		})
	},[])

	const handleLogin= () =>{
		signInWithEmailAndPassword(auth, email, password)
			.then(()=>{
				window.location="/adminView"
			})
			.catch(()=>{
				setError('Incorrect email or password')
			})
	}

	return(
		<Box>
			<Helmet defer={false}>
				<title>Admin Login</title>
				<meta name="robots" content="noindex, nofollow" />
			</Helmet>
			<Typography variant="h1">Login</Typography>
			<input type="text" value={email} placeholder="Email" onChange={(e)=>{
				setEmail(e.target.value)
			}} />
			<br />
			<input type="password" value={password} 
				placeholder="Password"
				onChange={(e)=>{
					setPassword(e.target.value)
				}} />
			<br />
			<button onClick={handleLogin}>Login</button>
			{error? 
				<p>{error}</p>
				:
				null
			}

		</Box>
	)
}

export default Login
